import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RequestSection.scss';
import { approveRequest, getPeople, getRequest, rejectRequest, revokeRequest } from './service';
import { AlignmentType, Button, IconButton, Modal, ModalBody, ModalFooter, ModalPlacement, ThemeType } from 'basicui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

interface Props {
  location: any;
  requestList: any[];
  peopleMap: any;
  onDataChange: any;
  auth: any;
  isUserAvailable: boolean;
}

const RequestSection = (props: Props) => {
  const navigate = useNavigate();
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const selectedRequestRef = useRef<any>();

  const onDeleteRequest = (request: any) => {
    revokeRequest(request._id, props.auth).then((response: any) => {
      props.onDataChange();
    })
  }

  const initiateApprovalFlow = (request: any) => {
    selectedRequestRef.current = request;
    setIsAcceptDialogOpen(true);
  }

  const initiateDeclineFlow = (request: any) => {
    selectedRequestRef.current = request;
    setIsDeclineDialogOpen(true);
  }

  const onApproveRequest = () => {
    approveRequest(selectedRequestRef.current._id, props.auth).then((response: any) => {
      props.onDataChange();
      closeApprovalDeclineFlow();
    })
  }

  const closeApprovalDeclineFlow = () => {
    selectedRequestRef.current = null;
    setIsAcceptDialogOpen(false);
    setIsDeclineDialogOpen(false);
  }

  const onRejectRequest = () => {
    rejectRequest(selectedRequestRef.current._id, props.auth).then((response: any) => {
      props.onDataChange();
      closeApprovalDeclineFlow();
    })
  }

  return (
    <>
      <Modal isOpen={isAcceptDialogOpen} onClose={closeApprovalDeclineFlow}>
        <ModalBody>
          <div>
            Once you accept an incoming request, your roommate will be finalized and no changes are possible after that. Do you want to continue accepting this request?
            <br /><br />
            <div className='small'>It is recommended to check the smoking preferences of your roommate, before accepting a request.</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onApproveRequest} theme={ThemeType.primary}>Yes, proceed</Button>
          <Button onClick={closeApprovalDeclineFlow} theme={ThemeType.default}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isDeclineDialogOpen} onClose={closeApprovalDeclineFlow}>
        <ModalBody>
          <div>
            Once you decline an incoming request, you no longer can choose the same person as your roommate. Are you sure you want to decline this request?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onRejectRequest} theme={ThemeType.primary}>Yes, proceed</Button>
          <Button onClick={closeApprovalDeclineFlow} theme={ThemeType.default}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <div className="request-page-request-section">
        {/* <h4>Active requests</h4> */}
        <div className="request-page-request-section__main">
          {props.requestList.map((item: any) => (
            <div className="request-page-request-section__main__item">
              <div className="request-page-request-section__main__item__left">
                <div className="request-page-request-section__main__item__left__top">
                  {props.auth?._id !== item.from && `${props.peopleMap[item.from]?.firstName} ${props.peopleMap[item.from]?.lastName}`}
                  {props.auth?._id !== item.to && `${props.peopleMap[item.to]?.firstName} ${props.peopleMap[item.to]?.lastName}`}
                </div>
                <div className="request-page-request-section__main__item__left__bottom">
                  {item.status === 'requested' && props.auth._id === item.to && <>Sent you a friend request</>}
                  {item.status === 'requested' && props.auth._id === item.from && <>Waiting for their approval</>}
                  {/* {item.status === 'approved' && props.auth._id === item.to && <>You have accepted the request</>}
                  {item.status === 'approved' && props.auth._id === item.from && <>They have accepted your request</>} */}
                  {item.status === 'rejected' && props.auth._id === item.to && <>You have declined the request</>}
                  {item.status === 'rejected' && props.auth._id === item.from && <>They have declined your request</>}
                </div>
                {item.status === 'approved' && <div className="request-page-request-section__main__item__left__two">
                  <FontAwesomeIcon icon={faPhone} />&nbsp;
                  {props.auth?._id !== item.from && props.peopleMap[item.from]?.phone}
                  {props.auth?._id !== item.to && props.peopleMap[item.to]?.phone}
                </div>}
              </div>

              <div>
                <div className="roommate-request-action-container">
                  {props.isUserAvailable && item.status === 'requested' && props.auth._id === item.to &&
                    <button className="roommate-request-action roommate-request-action--success" onClick={() => initiateApprovalFlow(item)}>
                      Accept
                    </button>}
                  {props.isUserAvailable && item.status === 'requested' && props.auth._id === item.to &&
                    <button className="roommate-request-action roommate-request-action--danger" onClick={() => initiateDeclineFlow(item)}>
                      Decline
                    </button>}
                  {item.status === 'requested' && props.auth._id === item.from &&
                    <button className="roommate-request-action" onClick={() => onDeleteRequest(item)}>
                      Withdraw
                    </button>}
                </div>
              </div>
            </div>))}
        </div>
      </div>
    </>
  );
};

export default RequestSection;
